/* eslint-disable import/no-unresolved */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'
import Top from 'components/layout/Header/Top'

// Hooks
import useLayout from 'hooks/useLayout'

// Third Party
import styled, { css } from 'styled-components'

const StyledHeader = styled.nav<{ isSticky: boolean }>`
  background: linear-gradient(
    180deg,
    rgba(13, 14, 115, 0.98) -23.61%,
    rgba(20, 2, 172, 0) 77.84%
  );
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 150px;
  z-index: 10;
  padding-bottom: 50px;

  ${(props) =>
    props.isSticky &&
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      background-color: ${({ theme }) => theme.color.primary};
    `}
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`

const Brand = styled(NavLink)`
  & > svg {
    height: 75px;
    width: 200px;
  }
  &:hover > svg {
    opacity: 0.8;
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
}) => {
  const layout = useLayout()

  // eslint-disable-next-line
  const query: any = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      nl_NL: wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }

      en_US: wpComponent(databaseId: { eq: 394 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const fields = query[layout.locale]

  return (
    <>
      <StyledHeader isSticky={isSticky}>
        <Top fields={fields} />

        <Container className="container">
          <Brand to={`${{ nl_NL: '/', en_US: '/en/' }[layout.locale]}`}>
            <Logo />
          </Brand>

          <Mobile
            fields={fields}
            setIsScrollable={setIsScrollable}
            isSticky={isSticky}
          />
          <Desktop fields={fields} isSticky={isSticky} />
        </Container>
      </StyledHeader>
    </>
  )
}

export default Header
