import React from 'react'
import styled, { css } from 'styled-components'

// Components
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Images
import Arrow from 'img/arrow.inline.svg'

export const ButtonCSS = css`
  color: ${({ theme }) => theme.color.text.primaryLight};
  font-family: ${({ theme }) => theme.font.family.secondary};
  line-height: 1;
  padding: 0;
  margin: 0;
  font-size: 14px;
  font-weight: ${({ theme }) => theme.font.weight.bold};
  border: 1px solid ${({ theme }) => theme.color.text.primaryLight};
  padding: 10px 15px;
  transition: all 0.3s ease;

  a,
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    transition: all 0.3s ease;

    &:hover {
      opacity: 1 !important;
    }
  }

  svg {
    width: 18px;
    height: 16px;
    margin-left: 10px;
    stroke-width: 3px;

    & path {
      transition: all 0.3s ease;
    }
  }

  ${(props) =>
    Object.entries(props.theme.color).map(
      ([key, value]) =>
        `
          &.${key} {
            color: ${value};
            border-color: ${value};

            &:not(.filled) a {
              color: ${value};
            }

            &:hover {
              background-color: ${value};
            }
          }

          &.hover-${key} {
            &:hover {
              & a, button {
                color: ${value} !important;
              }

              & svg {
                path {
                  fill: ${value} !important;
                }
              }
            }
          }

          &.${key}.transparent {
            background-color: transparent;
            border-color: transparent;

            &.colored a, button {
              color: ${value};
            }

            a, button {
              color: ${value};
            }

            svg {
              path {
                transition: all 0.3s ease;
                fill: ${value};
              }
              color: ${value};
              transition: all 0.3s ease;
            }
          }

          &.${key}.filled-white {
            background-color: ${props.theme.color.light};
            border-color: ${props.theme.color.light};

            &.colored a, button {
              color: ${value};
            }

            a, button {
              color: ${props.theme.color.dark};
            }

            svg {
              color: ${value};
            }
          }

          &.${key}.filled {
            background-color: ${value};
            border-color: ${value};

            a, button {
              color: ${key === 'secondary'
          ? props.theme.color.primaryLight
          : props.theme.color.secondary
        };
            }

            svg {
              path {
                fill: ${key === 'secondary'
          ? props.theme.color.primaryLight
          : props.theme.color.secondary
        };
                transition: all 0.3s ease;
              }
            }

            &:hover {
              border-color: ${key === 'secondary'
          ? props.theme.color.primaryLight
          : props.theme.color.primaryLight
        };
              background-color: ${key === 'secondary'
          ? props.theme.color.primaryLight
          : props.theme.color.primaryLight
        };
            }
          }
        `
    )}
`

// @ts-ignore
const StyledArrow = styled(Arrow) <{ className: string }>``

const StyledButton = styled.button`
  ${ButtonCSS}

  ${(props) =>
    props.type !== undefined &&
    css`
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0.5em 1em 0.5em 1em;
    `};
`

interface ButtonDefaultProps {
  isAnchor?: boolean
  isCustom?: boolean
  to?: string
  children: any
  className?: string
  arrow?: boolean
  target?: string
  type?: 'submit' | 'button'
  onClick?: () => void
}

const Button: React.FC<ButtonDefaultProps> = ({
  isAnchor = false,
  to = '',
  children,
  className = '',
  arrow = false,
  type = undefined,
  target = '_self',
  onClick = () => null,
}) => (
  <StyledButton onClick={onClick} type={type} className={className} target={target}>
    {to === '' ? (
      <>
        <span>{children}</span>
        {arrow && <StyledArrow className="arrow" />}
      </>
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        <span>{children}</span>

        {arrow && <StyledArrow className="arrow" />}
      </ButtonShell>
    )}
  </StyledButton>
)

export default Button
