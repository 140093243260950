import React from 'react'

// Hooks
import useLayout from 'hooks/useLayout'

// Components
import LanguageSwitch from 'components/elements/LanguageSwitch'
import ThemeSwitch from 'components/elements/ThemeSwitch'
import Button from 'components/elements/Buttons/Button'

// Third Party
import styled from 'styled-components'

interface TopProps {
  fields: any
}

const StyledTop = styled.section`
  height: 50px;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.color.light};
  transition: all 0.3s ease;
  z-index: 99;
`

const StyledButton = styled(Button)`
  @media screen and (max-width: 350px) {
    padding: 5px 10px 5px 0px;

    & a,
    button {
      font-size: 12px;
    }
  }
`

const Top: React.FC<TopProps> = () => {
  const layout = useLayout()

  return (
    <StyledTop>
      <div className="container h-100">
        <div className="h-100 py-3 d-flex align-items-center justify-content-end">
          <div>
            <ThemeSwitch />
          </div>
          <div className="ms-lg-4 ms-3">
            <LanguageSwitch />
          </div>
          <div className="ms-lg-4 ms-3">
            <StyledButton
              arrow
              className="filled secondary hover-secondary"
              to={`${layout.locale === 'nl_NL' ? `https://app.loxo.co/career-dev-?type_sort=desc` : `https://app.loxo.co/career-dev-?type_sort=desc`
                }`}
              target="_blank"
            >
              {`${layout.locale === 'nl_NL'
                ? `Bekijk alle vacatures`
                : `See all vacancies`
                }`}
            </StyledButton>
          </div>
        </div>
      </div>
    </StyledTop>
  )
}

export default Top
