/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
}

const Item = styled.li`
  list-style: none;

  & > a {
    color: ${({ theme }) => theme.color.dark};

    &[aria-current] {
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & > a {
      font-size: 16px;
      line-height: 16px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.light};
    }
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;

  @media screen and (min-width: 992px) {
    position: absolute;
    overflow: hidden;
    padding-top: 15px;

    & > div {
      background-color: ${({ theme }) => theme.color.light};
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
      padding: 15px;
      border-radius: 5px;
    }
  }

  @media screen and (max-width: 991px) {
    position: relative;
    padding-top: unset;
    width: 100%;

    & > div {
      padding: 2px 5px 2px 10px;
    }
  }
`

const Submenu: React.FC<SubmenuProps> = ({ fields }) => (
  <StyledSubmenu
    initial={{ height: 0 }}
    animate={{ height: 'fit-content' }}
    exit={{ height: 0 }}
  >
    <div>
      {fields.map((f, index) => (
        <Item>
          <NavLink to={f?.link?.url || '/'} key={index}>
            {f?.link?.title}
          </NavLink>
        </Item>
      ))}
    </div>
  </StyledSubmenu>
)

export default Submenu
