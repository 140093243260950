import React from 'react'

// Images
import Sun from 'img/sun.inline.svg'
import Moon from 'img/moon.inline.svg'

// Hooks
import useLayout from 'hooks/useLayout'
import { LayoutContextProps } from 'components/layout/Layout'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface ThemeSwitchProps {}

const Switch = styled(motion.div)`
  height: 13px;
  width: 13px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.secondary};
`

const SwitchWrapper = styled(motion.div)`
  border: 1px solid ${({ theme }) => theme.color.text.primaryLight};
  border-radius: 20px;
  width: 30px;
  height: 17px;
  display: flex;
  align-items: center;
  padding: 0 1px;
  cursor: pointer;
`

const Lang = styled.div`
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 14px;
  color: ${({ theme }) => theme.color.text.primaryLight};

  & svg {
    & path {
      fill: ${({ theme }) => theme.color.text.primaryLight};
    }
  }
`

const ThemeSwitch: React.FC<ThemeSwitchProps> = () => {
  const layout: LayoutContextProps = useLayout()

  let currentMode = false

  if (typeof window !== 'undefined') {
    currentMode = localStorage.getItem('darkmode') === 'true'
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        {layout.darkMode}
        <Lang className="me-1">
          <Sun />
        </Lang>
        <SwitchWrapper
          onClick={() =>
            layout.darkMode
              ? layout.setDarkMode(false)
              : layout.setDarkMode(true)
          }
          animate={layout.darkMode ? 'dark' : 'light'}
          variants={{ light: {}, dark: {} }}
        >
          <Switch
            initial={currentMode ? { x: 13 } : { x: 0 }}
            variants={{
              light: { x: 0 },
              dark: { x: 13 },
            }}
          />
        </SwitchWrapper>
        <Lang className="ms-1">
          <Moon />
        </Lang>
      </div>
    </div>
  )
}

export default ThemeSwitch
