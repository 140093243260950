/* eslint-disable no-script-url */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

// Images
import Linkedin from 'img/linkedin.inline.svg'
import Twitter from 'img/twitter.inline.svg'
import Facebook from 'img/facebook.inline.svg'

// Components
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled from 'styled-components'
import Button from 'components/elements/Buttons/Button'
import useLayout from 'hooks/useLayout'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  color: ${(props) => props.theme.color.light};

  & h2 {
    @media screen and (min-width: 991px) {
      font-size: 20px;
      line-height: 20px;
    }
  }

  & ul {
    padding-left: 12px;

    & li {
      list-style: none;
      position: relative;
      font-family: ${({ theme }) => theme.font.family.secondary};
      font-size: 16px;
      line-height: 25px;
      font-weight: ${({ theme }) => theme.font.weight.light};
      color: ${({ theme }) => theme.color.text.light};

      & a {
        &:hover {
          text-decoration: underline;
        }
      }

      &::before {
        content: '>';
        position: absolute;
        color: ${({ theme }) => theme.color.text.secondary};
        font-weight: ${({ theme }) => theme.font.weight.bold};
        font-size: 16px;
        left: -12px;
      }
    }
  }
`

const WithSideBar = styled.div`
  position: relative;

  &::before {
    content: '';
    position: absolute;
    height: 110%;
    width: 1px;
    background-color: ${({ theme }) => theme.color.secondary};
    right: 0;
    top: -5%;
  }
`

const Address = styled.div`
  position: relative;
  padding-left: 12px;

  &::before {
    content: '>';
    position: absolute;
    left: 0;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    font-family: ${({ theme }) => theme.font.family.secondary};
    color: ${({ theme }) => theme.color.text.secondary};
    font-size: 16px;
  }

  & p {
    font-size: 16px;
    line-height: 25px;
    color: ${({ theme }) => theme.color.text.light};
  }
`

const PhoneMail = styled.div`
  & p {
    font-size: 16px;
    line-height: 25px;
    color: ${({ theme }) => theme.color.text.light};
  }
`

const Socials = styled.div`
  padding-left: 12px;

  & a {
    & svg {
      transition: all 0.3s ease;
    }

    &:hover {
      & svg {
        opacity: 0.7;
      }
    }
  }

  @media screen and (max-width: 991px) {
    padding-left: 0;
    width: 100%;
  }
`

const ButtonWrapper = styled.div`
  @media screen and (max-width: 991px) {
    width: 100%;
  }
`

const Subtitle = styled.span`
  @media (min-width: 1199px) {
    font-size: 22px;
    letter-spacing: 4px;
    line-height: 15px;
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const layout = useLayout()

  const query: any = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      nl_NL: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menutitle
          menuFooter {
            link {
              title
              url
            }
          }
          careertitle
          careerFooter {
            link {
              title
              url
            }
          }
          contacttitle
          address
          phone
          email
          whatsapp
          linkedin {
            title
            url
          }
          twitter {
            title
            url
          }
          facebook {
            title
            url
          }
          chatlink {
            title
            url
          }
        }
      }

      en_US: wpComponent(databaseId: { eq: 396 }) {
        footer {
          menutitle
          menuFooter {
            link {
              title
              url
            }
          }
          careertitle
          careerFooter {
            link {
              title
              url
            }
          }
          contacttitle
          address
          phone
          email
          whatsapp
          linkedin {
            title
            url
          }
          twitter {
            title
            url
          }
          facebook {
            title
            url
          }
          chatlink {
            title
            url
          }
        }
      }
    }
  `)

const fields = query[layout.locale]

  

  return (
    <StyledFooter>
      <div className="container py-lg-5 py-3">
        <div className="row">
          <WithSideBar className="col-lg-2">
            <ParseContent content={fields?.footer?.menutitle || ''} />
            <div className="pt-lg-4 mt-2">
              <ul>
                {fields?.footer?.menuFooter?.map(
                  ({ link: { url, title } }: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                      <Link to={url}>{title}</Link>
                    </li>
                  )
                )}
                <li>
                  <Link
                    to={
                      layout.locale === 'nl_NL'
                        ? '/nl/privacyverklaring'
                        : '/privacy'
                    }
                  >
                    {layout.locale === 'nl_NL' ? (
                      <>Privacyverklaring</>
                    ) : (
                      <>Privacy</>
                    )}
                  </Link>
                </li>
              </ul>
            </div>
          </WithSideBar>
          <div className="col-lg-1" />
          <WithSideBar className="col-lg-3 pt-lg-0 pt-3">
            <ParseContent content={fields?.footer?.careertitle || ''} />
            <div className="pt-lg-4 mt-2">
              <ul>
                {fields?.footer?.careerFooter?.map(
                  ({ link: { url, title } }: any, index: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={index}>
                      <Link to={url}>{title}</Link>
                    </li>
                  )
                )}
              </ul>
            </div>
          </WithSideBar>
          <div className="col-lg-1" />
          <div className="col-lg-5 pt-lg-0 pt-3">
            <div className="d-flex flex-column flex-sm-row justify-content-between">
              <ParseContent content={fields?.footer?.contacttitle || ''} />
              <Subtitle className="text-secondary ms-4">
                embedding professionals
              </Subtitle>
            </div>
            <div className="pt-lg-4 mt-2">
              <div className="d-flex flex-wrap justify-content-between">
                <Address>
                  <ParseContent content={fields?.footer?.address || ''} />
                </Address>
                <PhoneMail className="pt-lg-0 pt-2">
                  <ParseContent content={fields?.footer?.phone || ''} />
                  <ParseContent content={fields?.footer?.email || ''} />
                  <ParseContent content={fields?.footer?.whatsapp || ''} />
                </PhoneMail>
              </div>
              <div className="pt-4 d-flex flex-wrap justify-content-between">
                <Socials className="d-flex justify-content-lg-start justify-content-center pb-lg-0 pb-4">
                  <div className="me-3">
                    <a
                      target="__blank"
                      href={fields?.footer?.linkedin?.url || '/'}
                    >
                      <Linkedin />
                    </a>
                  </div>
                  <div className="me-3">
                    <a
                      target="__blank"
                      href={fields?.footer?.twitter?.url || '/'}
                    >
                      <Twitter />
                    </a>
                  </div>
                  <div>
                    <a
                      target="__blank"
                      href={fields?.footer?.facebook?.url || '/'}
                    >
                      <Facebook />
                    </a>
                  </div>
                </Socials>
                <ButtonWrapper className="d-lg-block d-flex justify-content-center">
                  <Button
                    arrow
                    className="filled secondary hover-secondary"
                    isCustom
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <a
                      href="javascript:void(Tawk_API.toggle())"
                      className="py-1"
                    >
                      {fields?.footer?.chatlink?.title}
                    </a>
                  </Button>
                </ButtonWrapper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
