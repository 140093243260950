export const mainTheme = {
  color: {
    primary: '#09154F',
    primaryLight: '#1402AC',
    primaryAlt: '#1402AC',
    secondary: '#14E4B7',
    altSecondary: '#14E4B7',
    grey: '#F3F3F3',
    dark: '#000000',
    light: '#FFFFFF',
    permaLight: '#FFFFFF',
    greyDark: '#B9B9B9',

    text: {
      primary: '#09154F',
      primaryLight: '#1402AC',
      fatPrimaryLight: '#1402AC',
      secondary: '#14E4B7',
      light: '#FFFFFF',
      altLight: '#FFFFFF',
      dark: '#000000',
    },

    border: {
      primaryLight: '#1402AC',
    },
  },

  font: {
    family: {
      primary: 'Ubuntu Mono, monospace',
      secondary: 'Poppins, sans-serif',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 600,
      extraBold: 800,
    },
  },
}

export const darkTheme = {
  color: {
    primary: '#09154F',
    primaryLight: '#1402AC',
    primaryAlt: '#FFFFFF',
    secondary: '#14E4B7',
    altSecondary: '#FFFFFF',
    grey: '#343434',
    dark: '#FFFFFF',
    light: '#121212',
    permaLight: '#FFFFFF',
    greyDark: '#B9B9B9',

    text: {
      primary: '#09154F',
      primaryLight: '#FFFFFF',
      fatPrimaryLight: '#14E4B7',
      secondary: '#14E4B7',
      light: '#FFFFFF',
      altLight: '#14E4B7',
      dark: '#FFFFFF',
    },

    border: {
      primaryLight: '#FFFFFF',
    },
  },

  font: {
    family: {
      primary: 'Ubuntu Mono, monospace',
      secondary: 'Poppins, sans-serif',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '18px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      giga: '60px',
    },

    weight: {
      light: 300,
      regular: 400,
      bold: 600,
      extraBold: 800,
    },
  },
}
