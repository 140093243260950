/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useMedia } from 'react-use'

// Components
import NavLink from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'

// Images
import Chevron from 'img/chevron.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

const Item = styled(motion.li)`
  max-width: 120px;
  margin-left: 50px;

  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & div > a {
    color: ${(props) => props.theme.color.text.light};
    font-size: 16px;
    line-height: 16px;
    font-weight: ${({ theme }) => theme.font.weight.bold};

    & b,
    strong {
      color: ${({ theme }) => theme.color.secondary};
    }

    &[aria-current] {
      color: ${({ theme }) => theme.color.text.secondary};

      & b,
      strong {
        color: ${({ theme }) => theme.color.text.light};
      }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media screen and (max-width: 1199px) {
    margin-left: 32px;
  }

  @media screen and (max-width: 1024px) {
    margin-left: 20px;

    & div > a {
      font-size: 16px;
      line-height: 16px;
    }
  }

  @media screen and (max-width: 991px) {
    padding: 15px 0;
    margin-left: 0;

    & div > a {
      font-size: 30px;
      line-height: 30px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
}

const SvgWrapper = styled.div<{ open: boolean }>`
  right: -110px;

  & > svg {
    transition: all 0.3s ease;

    ${({ open }) =>
      open
        ? css`
            transform: rotate(180deg);
          `
        : css`
            transform: rotate(0deg);
          `};
  }
`

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [open, setOpen] = React.useState<number>(-1)

  const mobile = useMedia('(max-width: 991px)')

  return (
    <>
      {fields.header?.menuHeader?.map((l, index) => (
        <Item
          onHoverStart={() => setOpen(index)}
          onHoverEnd={() => setOpen(-1)}
          onTap={open === index ? () => setOpen(-1) : () => setOpen(index)}
          key={index}
        >
          <div className="position-relative">
            <NavLink className="position-relative" to={l?.link?.url || '/'}>
              {l?.link?.title}
              <b>_</b>
            </NavLink>
            {l?.submenu && mobile && (
              <SvgWrapper open={open === index} className="position-absolute">
                <Chevron />
              </SvgWrapper>
            )}
          </div>
          {l?.submenu && (
            <AnimatePresence>
              {open === index && <Submenu fields={l.submenu} />}
            </AnimatePresence>
          )}
        </Item>
      ))}
    </>
  )
}

export default Menu
