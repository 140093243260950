import { useContext } from 'react'

import { LayoutContext } from 'components/layout/Layout'

const useLayout = () => {
  const context = useContext(LayoutContext)

  return context
}

export default useLayout
