/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'

// Services
import convertStyle from 'services/convertStyle'

// Elements
import Button from 'components/elements/Buttons/Button'

const Content = styled.div`
  font-weight: ${(props) => props.theme.font.weight.light};
  font-size: ${({ theme }) => theme.font.size.medium};

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }

  & h1 {
    text-align: initial;
    font-weight: 400;

    & strong,
    & b {
      font-weight: 700;
    }

    @media (min-width: 992px) {
      font-size: 75px;
      line-height: 70px;
    }

    @media (max-width: 991px) {
      font-size: 40px;
      line-height: 50px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h2 {
    text-align: initial;
    font-weight: 400;

    & strong,
    & b {
      font-weight: 700;
    }

    @media (min-width: 992px) {
      font-size: 50px;
      line-height: 60px;
    }

    @media (max-width: 991px) {
      font-size: 30px;
      line-height: 30px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h3 {
    text-align: initial;
    font-weight: 400;

    & strong,
    & b {
      font-weight: 700;
    }

    @media (min-width: 992px) {
      font-size: 30px;
      line-height: 30px;
    }

    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 24px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h4 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};
    font-family: ${({ theme }) => theme.font.family.secondary};
    padding: 0;
    margin-bottom: 0;

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
      color: ${({ theme }) => theme.color.text.fatPrimaryLight};
    }

    @media (min-width: 992px) {
      font-size: 20px;
      line-height: 35px;
    }

    @media (max-width: 991px) {
      font-size: 20px;
      line-height: 35px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h5 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ul {
    font-family: ${({ theme }) => theme.font.family.secondary} !important;
    & li {
      font-family: ${({ theme }) => theme.font.family.secondary} !important;
    }
  }

  & p {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.light};
    font-size: 16px;
    line-height: 30px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
    transition: all 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  & img {
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: ${({ theme }) => theme.font.size.tiny};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }
`

const checkAndReplaceColor = (item: any) => {
  item.forEach((c: any) => {
    if (c.attribs && Object.keys(c.attribs).length === 0) {
      if (c.children) {
        checkAndReplaceColor(c.children)
      }
    } else if (c.attribs && c.attribs.style === 'color: #1402ac;') {
      c.attribs.style = undefined
      c.attribs.class = 'text-primaryLight'
    }
  })
}

const findAndReplace = (content: string) =>
  parse(content, {
    replace: (domNode) => {
      const { name, attribs, children }: any = domNode

      if (attribs && attribs.style === 'color: #1402ac;') {
        attribs.style = undefined
        attribs.class = 'text-primaryLight'
      }

      if (attribs && attribs.style === 'color: #000000;') {
        attribs.style = undefined
        attribs.class = 'text-dark'
      }

      if (children) {
        checkAndReplaceColor(children)
      }

      if (name === 'a' && attribs.class?.includes('button')) {
        return (
          <Button
            arrow={attribs.class.includes('arrow')}
            className={attribs.class}
            to={attribs.href}
          >
            {domToReact(children, {})}
          </Button>
        )
      }

      if (name === 'a') {
        const attributes = attribs

        if (children) {
          checkAndReplaceColor(children)
        }

        if (attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1 ||
          attributes.href.indexOf('tel:') !== -1 ||
          attributes.href.indexOf('mailto:') !== -1
        ) {
          return <a {...attributes}>{domToReact(children, {})}</a>
        }

        return (
          <Link to={attributes.href} {...attributes}>
            {domToReact(children, {})}
          </Link>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content: string
  className?: string
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
}) => {
  if (!content) {
    return null
  }

  return <Content className={className}>{findAndReplace(content)}</Content>
}

export default ParseContent
