// @ts-nocheck
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'

// Theme
import Theme from 'styles/Global'

// Components
import Header from 'components/layout/Header/Header'
import Footer from 'components/layout/Footer'

// CSS
import 'bootstrap/dist/css/bootstrap.css'
import 'styles/all.css'

export interface LayoutContextProps {
  locale: string
  darkMode: boolean
  pageId: number
  translations: Array<{
    href: string
    locale: string
  }>
  setIsScrollable: Function
  setDarkMode: Function
}

export const LayoutContext = React.createContext({
  pageId: 0,
  locale: 'nl_NL',
  darkMode:
    typeof window !== 'undefined'
      ? localStorage.getItem('darkmode') === 'true'
      : false,
  translations: [],
  setIsScrollable: (bool: any) => bool,
  setDarkMode: (bool: any) => bool,
})

const Content = styled.div<{ isSticky?: boolean }>`
  /* padding-top: 50px; */
`

interface LayoutProps {
  pageId?: number
  children: any
  locale?: string
  translations?: any
}

const Layout: React.FC<LayoutProps> = ({
  children,
  locale = 'nl_NL',
  translations,
  pageId = 0,
}) => {
  const [isLoaded, setLoaded] = useState<boolean>(false)
  const [isSticky, setSticky] = useState<boolean>(false)
  const [darkMode, setDarkMode] = useState<boolean>(false)
  const [isScrollable, setIsScrollable] = useState<boolean>(true)

  const handleScroll = () => {
    setSticky(window.scrollY > 0)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [handleScroll])

  const setDarkModeLocal = (value: boolean) => {
    setDarkMode(value)

    if (typeof window !== 'undefined') {
      localStorage.setItem('darkmode', value.toString())
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const currentMode = localStorage.getItem('darkmode') === 'true'

      setDarkMode(currentMode)
    }

    setLoaded(true)
  }, [])

  if (!isLoaded) {
    return null
  }

  var Tawk_API = Tawk_API || {}
  const Tawk_LoadStart = new Date()
  ;(function () {
    const s1 = document.createElement('script')
    const s0 = document.getElementsByTagName('script')[0]
    s1.async = true
    s1.src = 'https://embed.tawk.to/61810757f7c0440a59211471/1fjg09bpl'
    s1.charset = 'UTF-8'
    s1.setAttribute('crossorigin', '*')
    s0.parentNode.insertBefore(s1, s0)
  })()

  return (
    <Theme darkMode={darkMode} isScrollable={isScrollable}>
      <LayoutContext.Provider
        value={{
          pageId,
          setDarkMode: setDarkModeLocal,
          setIsScrollable,
          darkMode,
          locale,
          translations,
        }}
      >
        <Header isSticky={isSticky} setIsScrollable={setIsScrollable} />
        <Content isSticky={isSticky}>{children}</Content>
        <Footer />
      </LayoutContext.Provider>
    </Theme>
  )
}

export default Layout
