import React from 'react'
import { navigate } from 'gatsby'

// Hooks
import useLayout from 'hooks/useLayout'
import { LayoutContextProps } from 'components/layout/Layout'

// Third Party
import styled from 'styled-components'
import { motion } from 'framer-motion'

interface LanguageSwitchProps {}

const Switch = styled(motion.div)`
  height: 13px;
  width: 13px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.color.secondary};
`

const SwitchWrapper = styled(motion.div)`
  border: 1px solid ${({ theme }) => theme.color.text.primaryLight};
  border-radius: 20px;
  width: 30px;
  height: 17px;
  display: flex;
  align-items: center;
  padding: 0 1px;
  cursor: pointer;
`

const Lang = styled.div`
  font-family: ${({ theme }) => theme.font.family.secondary};
  font-weight: ${({ theme }) => theme.font.weight.bold};
  font-size: 14px;
  color: ${({ theme }) => theme.color.text.primaryLight};
`

const LanguageSwitch: React.FC<LanguageSwitchProps> = () => {
  const layout: LayoutContextProps = useLayout()

  const switchLocale = (findLocale: string): void => {
    if (layout.translations && layout.translations.length > 0) {
      const translation = layout.translations.find(
        ({ locale }: any) => locale === findLocale
      )

      if (translation) {
        let goTo = translation.href

        if (goTo.indexOf('/sample-page/') !== -1) {
          goTo = `/${goTo.split('/')[1]}`
        }

        // English's URI is not /en/ so we have to set it manually
        if (goTo === '/en/home/') {
          goTo = `/${goTo.split('/')[1]}/`
        }

        // On Development the URI for the "main" homepage is a absolute one, which is why we have to set it manually
        if (goTo === 'https://uwbusinessonline.site/careerdev-dev.nl') {
          goTo = '/'
        }

        navigate(goTo)
      }
    }
  }

  return (
    <div>
      <div className="d-flex align-items-center">
        <Lang className="me-1">NL</Lang>
        <SwitchWrapper
          onClick={() =>
            switchLocale(layout.locale === 'nl_NL' ? 'en_US' : 'nl_NL')
          }
          animate={
            layout.locale === 'nl_NL'
              ? { justifyContent: 'flex-start' }
              : { justifyContent: 'flex-end' }
          }
        >
          <Switch />
        </SwitchWrapper>
        <Lang className="ms-1">EN</Lang>
      </div>
    </div>
  )
}

export default LanguageSwitch
